.modal-fullscreen {
  position: absolute;
  top: 0;
  left:0;
  --width: 90%;
  --height: 90%;
}

ion-slides {
  height: 100%;
}

.swiper-slide-prev {
  height: 100%;
}

.swiper-slide-next {
  height: 100%;
}

/*
ion-slides {
  height: 100%;
  --bullet-background: #15b29d;
}
*/

.swiper_zoom_container {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-slide {
  /* Ensure the container of the image takes up the full size of the SwiperSlide */
  width: 100%; /* Full width of the slide */
  height: 100vh; /* Full height of the viewport */
  display: flex; /* Use flexbox to center the image */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.swiper-slide .zoom-image {
  max-width: 100%; /* Max width is the full width of its container */
  max-height: 100vh; /* Max height is the full viewport height */
  object-fit: contain;
}

.zoom-slide-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgb(9, 0, 3);
  width: 25px;
  height: 25px;
  _border-radius: 50%;
  line-height: 50px;
  text-align: center;
  color: #FFF;
  z-index: 2;
}

.img-delete {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgb(236, 5, 82);
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  color: #FFF;
  z-index: 2;
}

.swiper-slide-prev {
  height: 160px;
}
.swiper-slide-next {
  height: 160px;
}

.swiper-button-next {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}