.categoryPage ion-toolbar {

    --border-style: none;
}

.categoryCard {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    /* min-height: 20rem !important; */
}

.productCard {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.productCardActions {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
}

.productCardAction {

    font-size: 1.1rem;
}

.productCardHeader {

    min-height: 17rem;
}

.productCardHeader p {

    font-size: 0.8rem;
    padding: 0;
    margin: 0;
    margin-top: 0.75rem;
}

.categoryCardContent {

    display: flex;
    flex-direction: column;
}

.categoryCardContent ion-button {

    height: 1.5rem;
    font-size: 0.8rem;
}

.categoryCardContent p {

    font-size: 0.8rem;
    padding: 0;
    margin: 0;
}

.categoryCard img {

    /* border-radius: 5px; */
    /* padding: 1rem; */
}

.productPrice {

    display: flex;
    flex-direction: row;
}

.productImage {
    height: 200px; /* Example height, adjust as needed */
    object-fit: cover; /* This will ensure the image covers the area without losing its aspect ratio */
    width: 100%; /* Ensure the image fills the width of its container */
}

.discountBadge {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--ion-color-drukre);
    color: white;
    padding: 0.5rem;
    border-radius: 0 0 0 0.5rem;
}