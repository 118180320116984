.cartCheckout {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 1rem;
}

.cartFooter {

    border-top: 2px solid rgb(231, 228, 228);
}

.cartCheckout ion-card-subtitle {
    font-size: 1rem;
    background-color: rgb(243, 45, 71);
    padding: 0.5rem;
    color: white;
    border-radius: 5px;
}

.cartItem ion-avatar {

    height: 4rem;
    width: 4rem;
}

.cartSlider:not(:nth-child(1)) {

    border-top: 2px solid rgb(236, 236, 236);
}

.cartActions {

    display: flex;
    flex-direction: column;
}