ion-loading.drukre-loading {
    --background: #e3edff;
    --spinner-color: #15b29d;

    color: #0c0606;
}

.grecaptcha-badge {
    visibility: hidden !important;
}

ion-radio.ios::part(container) {
    width: 20px;
    height: 20px;

    border: 2px solid #ddd;
    border-radius: 4px;
}

.radio-checked.ios::part(container) {
    border-color: #15b29d;
}

.error-message {
    color: #ff0000;
    font-size: 12px;
    text-align: center;
}