@media (min-width: 765px) {
    ion-modal#notification-modal {
        --border-radius: 3px;
        --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

        &::part(content) {
            position: absolute;
            top: 5%;
            bottom: 20%;
            left: 75%;
            width: 20%;
        }
    }
}

ion-modal#notification-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

ion-modal#notification-modal ion-toolbar {
    --color: ;
}

@media (max-width: 765px) {
    ion-modal#notification-modal {
        
        &::part(content) {
            position: absolute;
            top: 35%;
            left: 10%;
            width: 80%;
            --min-height: 50vh;
            --border-radius: 6px;
        }
    }
}
